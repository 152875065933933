<div *ngIf="cart" class="">
    <ng-container *ngIf="cart.lines.length === 0">
        <div class="flex items-center justify-center h-32">
            <div class="text-center">
                <p class="text-2xl text-gray-400">Your cart is empty!</p>
                <a [routerLink]="['/']" class="text-primary-400 hover:text-primary-600">Let's fill it up</a>
            </div>
        </div>
    </ng-container>
    <ng-container *ngFor="let line of cart.lines; trackBy: trackByFn">
        <div
            [ngClass]="{
                'mb-4 pb-4': compact,
                'mb-6 pb-6': !compact,
                'bg-red-50 p-1': 0 < line.stockShortfall
            }"
            class="border-b border-gray-200"
        >
            <div class="flex">
                <div class="mr-4">
                    <div class="relative" [ngClass]="{ 'w-12 h-12': compact }">
                        <kb-picture
                            [lazy]="lazyLoadImages"
                            alt="Product thumbnail"
                            imgClass="rounded-lg border border-gray-50 shadow-sm"
                            [src]="
                                line.productVariant.product.featuredAsset?.id !== line.featuredAsset?.id &&
                                line.productVariant.product.customFields.pageType === 'colour-chart'
                                    ? line.productVariant.product.featuredAsset?.preview
                                    : line.featuredAsset?.preview
                            "
                            [w]="85"
                            [h]="85"
                        ></kb-picture>
                        <div
                            class=""
                            *ngIf="
                                line.productVariant.product.featuredAsset?.id !== line.featuredAsset?.id &&
                                line.productVariant.product.customFields.pageType === 'colour-chart'
                            "
                        >
                            <kb-picture
                                [lazy]="lazyLoadImages"
                                [ngClass]="{
                                    'w-4 h-4 bottom-0 left-0': compact,
                                    'bottom-1 left-1': !compact
                                }"
                                class="absolute"
                                imgClass="shadow-sm"
                                alt="Variant thumbnail"
                                [src]="line.featuredAsset?.preview"
                                [w]="24"
                                [h]="24"
                            ></kb-picture>
                        </div>
                    </div>
                </div>
                <div class="flex-1">
                    <div class="font-medium text-gray-700 text-sm">
                        <a [routerLink]="['/p', line.productVariant.product.slug]">{{
                            line.productVariant?.name
                        }}</a>
                    </div>
                    <div class="text-gray-700 text-sm">{{ line.productVariant?.sku }}</div>
                    <div
                        class="text-sm text-secondary-800 bg-secondary-100 rounded inline-block px-1"
                        *ngIf="line.customFields.freeGiftDescription"
                    >
                        Free gift
                    </div>
                    <div
                        class="text-sm text-gray-600"
                        *ngIf="line.customFields.addedFromWishlistCustomerName"
                    >
                        from {{ line.customFields.addedFromWishlistCustomerName }}'s wishlist
                    </div>
                    <div class="text-sm text-gray-600" *ngIf="line.giftCardInput?.recipientEmailAddress">
                        to: {{ line.giftCardInput.recipientEmailAddress }}
                    </div>
                    <div class="text-sm text-gray-600" *ngIf="line.giftCardInput?.message">
                        message: {{ line.giftCardInput.message | truncate:25 }}
                    </div>
                    <div class="text-sm text-gray-600" *ngIf="line.giftCardInput?.templateName">
                        design: {{ line.giftCardInput.templateName | truncate:25 }}
                    </div>
                    <div
                        [ngClass]="{
                            'mt-1': compact,
                            'mt-4': !compact
                        }"
                        class="flex items-start space-x-4 justify-between text-sm mt-4"
                    >
                        <div class="pt-1">
                            <div
                                class="text-gray-600 font-medium"
                                [class.line-through]="line.discountedUnitPriceWithTax < line.unitPriceWithTax"
                            >
                                {{ line.unitPriceWithTax | formatPrice }}
                            </div>
                            <div
                                class="text-sienna font-medium"
                                *ngIf="line.discountedUnitPriceWithTax < line.unitPriceWithTax"
                            >
                                {{ line.discountedUnitPriceWithTax | formatPrice }}
                            </div>
                        </div>
                        <div class="text-center">
                            <div class="inline-block" [ngClass]="updatingLine.get(line.id) === true ? 'animate-pulse' : ''">
                                <div
                                    [ngClass]="{
                                        border: canChangeLineQuantity(line)
                                    }"
                                    class="relative flex items-center justify-center border rounded p-1"
                                >
                                    <button
                                        class="px-2 text-gray-400 border-gray-200 rounded-l-md border-r-0"
                                        *ngIf="canChangeLineQuantity(line)"
                                        (click)="decrement(line)"
                                    >
                                        <fa-icon aria-label="Remove 1" [icon]="minus"></fa-icon>
                                    </button>
                                    <div class="px-1">
                                        {{ line.quantity }}
                                    </div>
                                    <button
                                        class="px-2 text-gray-400 border-gray-200 rounded-r-md border-l-0"
                                        *ngIf="canChangeLineQuantity(line)"
                                        (click)="increment(line)"
                                    >
                                        <fa-icon aria-label="Add 1" [icon]="plus"></fa-icon>
                                    </button>
                                    @if (updatingLine.get(line.id) === true) {
                                        <kb-spinner class="absolute w-4 h-4 fill-gray-300" />
                                    }
                                </div>
                                <button
                                    *ngIf="canRemoveLine(line)"
                                    (click)="remove(line)"
                                    class="sm:mt-1 text-xs text-yellow-600 hover:text-yellow-900"
                                >
                                    Remove
                                </button>
                            </div>
                            <div *ngIf="0 < line.stockShortfall" class="text-xs text-red-800 flex space-x-2">
                                <fa-icon [icon]="warning"></fa-icon>
                                <div *ngIf="line.stockShortfall === line.quantity">
                                    This product is no longer in stock
                                </div>
                                <div *ngIf="line.stockShortfall < line.quantity">
                                    Only {{ line.quantity - line.stockShortfall }} currently available
                                </div>
                                <button
                                    *ngIf="line.stockShortfall < line.quantity"
                                    (click)="setQuantity(line.id, line.quantity - line.stockShortfall)"
                                    class="text-xs text-yellow-600 hover:text-yellow-900"
                                >
                                    Buy {{ line.quantity - line.stockShortfall }}
                                </button>
                                <button
                                    (click)="remove(line)"
                                    class="text-xs text-yellow-600 hover:text-yellow-900"
                                >
                                    Remove
                                </button>
                            </div>
                            <ng-container *ngIf="getMinimumOrderCount(line) as minimumCount">
                                <div
                                    class="text-xs"
                                    [ngClass]="
                                        minimumCount.minimum <= minimumCount.total
                                            ? 'text-green-700'
                                            : 'text-red-800'
                                    "
                                >
                                    <fa-icon
                                        [icon]="
                                            minimumCount.minimum <= minimumCount.total ? check : warning
                                        "
                                    ></fa-icon>
                                    {{ minimumCount.total }} of a minimum {{ minimumCount.minimum }}
                                </div>
                            </ng-container>
                        </div>
                        <div class="pt-1">
                            <div
                                class="text-gray-600 font-medium"
                                [class.line-through]="line.discountedLinePriceWithTax < line.linePriceWithTax"
                            >
                                {{ line.linePriceWithTax | formatPrice }}
                            </div>
                            <div
                                class="text-sienna font-medium"
                                *ngIf="line.discountedLinePriceWithTax < line.linePriceWithTax"
                            >
                                {{ line.discountedLinePriceWithTax | formatPrice }}
                            </div>
                        </div>
                    </div>
                    <ng-container *ngIf="getShippingRestrictions(line) as shippingInfo">
                        <kb-shipping-info-label [shippingInfo]="shippingInfo"></kb-shipping-info-label>
                    </ng-container>
                </div>
            </div>
        </div>
    </ng-container>
</div>
