import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Pipe,
    PipeTransform,
    SimpleChanges,
} from '@angular/core';

import { Subscription } from 'rxjs';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterLink } from '@angular/router';
import { NgIf, NgFor, NgClass } from '@angular/common';
import { faCheck, faMinus, faPlus, faWarning } from '@fortawesome/free-solid-svg-icons';
import { finalize } from 'rxjs/operators';
import { CartFragment, CustomerShippingLocation } from '../../../common/gql/graphql';

import { ActiveService } from '../../../core/providers/active-order/active.service';
import {
    buildMinimumOrderAmountMap,
    MinimumOrderAmountItem,
} from '../../../common/utils/build-minimum-order-amount-map';
import { getShippingRestrictions } from '../../../common/utils/get-shipping-restrictions';
import { OrderService } from '../../../core/providers/order/order.service';
import { FormatPricePipe } from '../../pipes/format-price.pipe';
import { ShippingInfoLabelComponent } from '../shipping-info-label/shipping-info-label.component';
import { PictureComponent } from '../picture/picture.component';
import { SpinnerComponent } from '../spinner/spinner.component';
import { NotificationService } from '../../../core/providers/notification/notification.service';

@Pipe({
    standalone: true,
    pure: true,
    name: 'truncate',
})
export class TruncatePipe implements PipeTransform {
    transform(value: string, length = 50) {
        if (value.length <= length) {
            return value;
        } else {
            return value.substring(0, length - 3) + '...';
        }
    }
}

@Component({
    selector: 'vsf-cart-contents',
    templateUrl: './cart-contents.component.html',
    styleUrls: ['./cart-contents.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        RouterLink,
        NgFor,
        NgClass,
        PictureComponent,
        FontAwesomeModule,
        ShippingInfoLabelComponent,
        FormatPricePipe,
        TruncatePipe,
        SpinnerComponent,
    ],
})
export class CartContentsComponent implements OnInit, OnChanges, OnDestroy {
    @Input() cart: CartFragment & {
        lines: Array<CartFragment['lines'][number] & { stockShortfall?: number }>;
    };
    @Input() canAdjustQuantities = false;
    @Input() compact = false;
    @Input() lazyLoadImages = false;
    private subscription: Subscription;
    private defaultShippingLocation: CustomerShippingLocation | undefined;
    private minimumOrderAmountMap = new Map<string, MinimumOrderAmountItem>();
    protected updatingLine = new Map<string, boolean>();
    minus = faMinus;
    plus = faPlus;
    check = faCheck;
    warning = faWarning;

    constructor(
        private activeService: ActiveService,
        private changeDetector: ChangeDetectorRef,
        private orderService: OrderService,
        private notificationService: NotificationService,
    ) {}

    ngOnInit() {
        this.subscription = this.activeService.defaultShippingLocation$.subscribe((location) => {
            this.defaultShippingLocation = location;
            this.changeDetector.markForCheck();
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if ('cart' in changes) {
            this.minimumOrderAmountMap = buildMinimumOrderAmountMap(this.cart);
        }
    }

    ngOnDestroy() {
        this.subscription?.unsubscribe();
    }

    trackByFn(index: number, line: { id: string }) {
        return line.id;
    }

    canChangeLineQuantity(line: this['cart']['lines'][number]): boolean {
        return (
            this.canAdjustQuantities &&
            !line.customFields.freeGiftDescription &&
            !line.stockShortfall &&
            line.giftCardInput?.value == null
        );
    }

    canRemoveLine(line: this['cart']['lines'][number]): boolean {
        if (line.customFields.freeGiftDescription && line.discounts.length) {
            return false;
        }
        return this.canAdjustQuantities && !line.stockShortfall;
    }

    increment(item: CartFragment['lines'][number]) {
        this.setQuantity(item.id, item.quantity + 1);
    }

    decrement(item: CartFragment['lines'][number]) {
        this.setQuantity(item.id, item.quantity - 1);
    }

    remove(item: CartFragment['lines'][number]) {
        this.setQuantity(item.id, 0);
    }

    getShippingRestrictions(item: CartFragment['lines'][number]) {
        return getShippingRestrictions(
            this.defaultShippingLocation,
            item.productVariant.product?.shippingRestrictions,
        );
    }

    getMinimumOrderCount(
        item: CartFragment['lines'][number],
    ): { minimum: number; total: number } | undefined {
        const minimumOrderAmount = this.minimumOrderAmountMap.get(item.productVariant.product.id);
        if (minimumOrderAmount) {
            return {
                minimum: minimumOrderAmount.minimum,
                total: minimumOrderAmount.total,
            };
        }
    }

    setQuantity(itemId: string, quantity: number) {
        this.updatingLine.set(itemId, true);
        this.orderService
            .updateOrderLine({ orderLineId: itemId, quantity })
            .pipe(
                finalize(() => {
                    this.updatingLine.delete(itemId);
                    this.changeDetector.markForCheck();
                }),
            )
            .subscribe({
                error: (err) => {
                    if (err.graphQLErrors?.[0]?.extensions?.code === 'ENTITY_NOT_FOUND') {
                        // Variant is no longer available, so delete it from the order
                        this.orderService.removeOrderLine(itemId).subscribe(() => {
                            this.notificationService
                                .error(`This product is no longer available, so has been removed`)
                                .subscribe();
                            this.changeDetector.markForCheck();
                        });
                    }
                },
            });
    }
}
